import { LogoPhantom } from "./icons/LogoPhantom";
import { LogoBikegrid } from "./icons/LogoBikegrid";
import { LogoCaptians } from "./icons/LogoCaptians";
import { LogoWorpel } from "./icons/LogoWorpel";
import { LogoFinals } from "./icons/LogoFinals";
import { LogoKeeper } from "./icons/LogoKeeper";

export function LogoStrip() {
  return (
    <section className="mb-8 bg-white px-4 dark:bg-gray-900 md:mb-12">
      <div className="mx-auto max-w-screen-lg lg:px-8">
        <div className="grid grid-cols-2 gap-4 space-y-0 pt-2 md:grid-cols-4 md:gap-8">
          <a
            href="https://keeperfantasy.com/?utm_source=gridley&utm_medium=affiliate&utm_campaign=footer"
            target="_blank"
            className="group flex items-center justify-center rounded bg-gray-50 p-8 text-center saturate-0 transition-all hover:bg-gray-100 hover:saturate-100 dark:bg-gray-800 dark:hover:bg-gray-700"
          >
            <LogoKeeper className="w-40 transition-all group-hover:scale-105" />
          </a>
          <a
            href="https://playworpel.com/?utm_source=gridley&utm_medium=affiliate&utm_campaign=footer"
            target="_blank"
            className="group flex items-center justify-center rounded bg-gray-50 p-8 text-center saturate-0 transition-all hover:bg-gray-100 hover:saturate-100 dark:bg-gray-800 dark:hover:bg-gray-700"
          >
            <LogoWorpel className="w-40 text-[#005979] transition-all group-hover:scale-105" />
          </a>
          <a
            href="https://finalsfantasy.com.au/?utm_source=gridley&utm_medium=affiliate&utm_campaign=footer"
            target="_blank"
            className="flex items-center justify-center rounded bg-gray-50 p-8 text-center hover:bg-gray-100 dark:bg-gray-800 dark:hover:bg-gray-700"
          >
            <LogoFinals className="h-12 " />
          </a>

          <a
            href="https://captainschallenge.com/?utm_source=gridley&utm_medium=affiliate&utm_campaign=footer"
            target="_blank"
            className="flex items-center justify-center rounded bg-gray-50 p-8 text-center hover:bg-gray-100 dark:bg-gray-800 dark:hover:bg-gray-700"
          >
            <LogoCaptians className="h-12 text-gray-600 dark:text-gray-400" />
          </a>
          {/* <a
            href="https://bikegrid.escapecollective.com/"
            target="_blank"
            className="flex items-center justify-center rounded bg-gray-50 p-8 text-center hover:bg-gray-100 dark:bg-gray-800 dark:hover:bg-gray-700"
          >
            <LogoBikegrid className="h-10 text-gray-600 dark:text-gray-400" />
          </a> */}
          {/* <a
            href="https://draftphantom.com/?utm_source=gridley&utm_medium=affiliate&utm_campaign=footer"
            target="_blank"
            className="flex items-center justify-center rounded bg-gray-50 p-8 text-center hover:bg-gray-100 dark:bg-gray-800 dark:hover:bg-gray-700"
          >
            <LogoPhantom className="w-44 text-gray-600 dark:text-gray-400" />
          </a> */}
        </div>
        {/* <h1 className="mx-auto mt-6 text-center text-sm text-gray-500 dark:text-gray-400">
              Check out more great games from the Gridley network
            </h1> */}
      </div>
    </section>
  );
}
