import { useEffect, useState } from "react";
import { IconCards } from "./icons/IconCards";
import { IconCardsOutline } from "./icons/IconCardsOutline";
import usePersistentStore from "../lib/store/PersistentStore";
import { LogoKeeper } from "./icons/LogoKeeper";
import { LogoKeeperWhite } from "./icons/LogoKeeperWhite";

export function PromoBanner() {
  const [cardMode, toggleCardMode] = usePersistentStore((state) => [
    state.cardMode,
    state.toggleCardMode,
  ]);

  const [selected, setSelected] = useState(false);

  useEffect(() => {
    if (cardMode) {
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [cardMode]);

  function toggleMode() {
    toggleCardMode();
  }

  return (
    // <div className="w-screen bg-red-600 p-2 px-6 text-center text-sm font-medium text-white sm:text-sm md:p-3">
    //   {/* <span className="hidden md:inline-block">Watch Now</span> */}
    //   Happy Holidays from the Gridley Team! 🎅🎄🎉
    //   {/*
    //   <a
    //     className="whitespace-nowrap text-blue-600 underline dark:text-blue-400 "
    //     href="https://twitter.com/GridleyGame/status/1683507357962375168"
    //     target="_blank"
    //   >
    //     Most Popular
    //   </a>{" "}
    //   <span className="md:hidden">Note: </span>Brisbane{" "}
    //   <strong>now includes</strong> Brisbane Bears players */}
    // </div>
    // <div className="w-screen bg-yellow-100 p-2 px-6 text-center text-sm font-medium text-gray-800 sm:text-sm md:p-3">
    //   {/* <span className="hidden md:inline-block">Watch Now</span> */}
    //   Draftees and 2025 playing lists have landed!
    //   {/*
    //   <a
    //     className="whitespace-nowrap text-blue-600 underline dark:text-blue-400 "
    //     href="https://twitter.com/GridleyGame/status/1683507357962375168"
    //     target="_blank"
    //   >
    //     Most Popular
    //   </a>{" "}
    //   <span className="md:hidden">Note: </span>Brisbane{" "}
    //   <strong>now includes</strong> Brisbane Bears players */}
    // </div>
    <div className="flex w-screen items-center justify-center bg-black p-2 px-6 text-center text-sm font-medium text-gray-800 sm:text-sm md:p-3">
      {/* <span className="hidden md:inline-block">Watch Now</span> */}
      <a
        className="inline-flex items-center justify-center whitespace-nowrap text-white "
        href="https://keeperfantasy.com/?utm_source=gridley&utm_medium=affiliate&utm_campaign=banner"
        target="_blank"
      >
        {/* <span className="mr-2 font-semibold uppercase  sm:ml-1.5">Play</span> */}
        <LogoKeeperWhite className="-my-2 h-5 sm:h-6" />
        <span className="ml-3 font-light opacity-50">|</span>
        <span className="ml-3 font-semibold uppercase italic sm:ml-1.5">
          Draft Fantasy <span className="text-[#ED1C24] ">Evolved.</span>
        </span>
      </a>{" "}
    </div>
    // <div className="w-screen bg-[#005979] p-2 text-center text-sm font-medium text-white sm:text-sm md:p-3">
    //   {/* <span className="hidden md:inline-block">Watch Now</span> */}
    //   WORPEL is back with new players –{" "}
    //   <a
    //     className="whitespace-nowrap text-white underline dark:text-blue-400 "
    //     href="https://playworpel.com/?utm_source=gridley&utm_medium=affiliate&utm_campaign=banner"
    //     target="_blank"
    //   >
    //     Play now!
    //   </a>
    // </div>
    // <div className="w-screen bg-gray-800 p-2 text-center text-sm text-white sm:text-sm md:p-3">
    //   {/* <span className="hidden md:inline-block">Watch Now</span> */}
    //   Hidden gem{" "}
    //   <a
    //     className="whitespace-nowrap font-medium text-white underline dark:text-white"
    //     href="https://theinsideword.blog/2024/08/20/failed-afl-rising-star-nominees/?utm_source=gridley&utm_medium=affiliate&utm_campaign=banner"
    //     target="_blank"
    //   >
    //     Rising Star Nominees
    //   </a>{" "}
    //   – The Inside Word
    // </div>
  );
}
