import { BaseDialog } from "./BaseDialog";
import { playerList } from "../../lib/utils/playerList";
import { UndoButton } from "../buttons/UndoButton";
import useGameStore from "../../lib/store/GameStore";
import usePersistentStore from "../../lib/store/PersistentStore";

function PickHistoryDialog(props) {
  const [isOpen, setIsOpen, levelNumber] = useGameStore((state) => [
    state.isPickHistoryOpen,
    state.setIsPickHistoryOpen,

    state.levelNumber,
  ]);

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <BaseDialog
      isOpen={isOpen}
      closeModal={closeModal}
      modalTitle={
        <div className="">
          <div className="text-center text-2xl font-semibold uppercase dark:text-white">
            Pick History
          </div>
          <div className="text-center text-sm font-semibold uppercase text-gray-500 dark:text-gray-300">
            Gridley Game {levelNumber}
          </div>
        </div>
      }
      modalBody={<ModalBody />}
    />
  );
}

function findPlayerById(playerId) {
  return playerList.find((player) => player.id === playerId);
}

function ModalBody() {
  const [incorrectGuesses, correctGuesses] = usePersistentStore((state) => [
    state.incorrectGuesses,
    state.correctGuesses,
  ]);

  const list = [];

  for (let row = 1; row <= 3; row++) {
    for (let col = 1; col <= 3; col++) {
      const incorrect = incorrectGuesses[row - 1][col - 1];
      incorrect.forEach((playerId) => {
        const player = findPlayerById(playerId);
        const correct = false;

        list.push(
          <HistoryListItem
            key={`${col}-${row}-${player.id}`}
            col={col}
            row={row}
            player={player}
            correct={correct}
          />
        );
      });

      const correctId = correctGuesses[row - 1][col - 1];
      if (correctId) {
        const player = findPlayerById(correctId);
        const correct = true;

        list.push(
          <HistoryListItem
            key={`${col}-${row}-${player.id}`}
            col={col}
            row={row}
            player={player}
            correct={correct}
          />
        );
      }
    }
  }

  return (
    <div className="-mx-4 -mb-3">
      <ul className="z-50 border-t border-t-gray-100 px-2 py-2 text-sm text-gray-800 dark:bg-gray-700">
        {list.length ? (
          list
        ) : (
          <li
            className={`cursor-default select-none items-center justify-between px-2 py-4 text-center font-medium leading-snug text-gray-600
            `}
          >
            No picks made yet today
          </li>
        )}
      </ul>
    </div>
  );
}

function HistoryListItem({ col, row, player, correct }) {
  const [undoGuess, undoUsed] = usePersistentStore((state) => [
    state.undoGuess,
    state.undoUsed,
  ]);

  return (
    <li
      key={`${col}-${row}-${player.id}`}
      className={`flex cursor-default select-none items-center justify-between px-2 py-2 font-medium leading-snug hover:bg-gray-50
            `}
    >
      <div className="flex items-center justify-start">
        <div className="mr-3 w-7">
          <MiniGrid col={col} row={row} correct={correct} />
        </div>
        <div
          className={`${
            correct ? "text-gray-900" : "text-red-600 dark:text-red-400"
          }`}
        >
          {player.name}
          <div className="-mt-0.5 text-xs font-normal leading-snug text-gray-500 dark:text-gray-400">
            {player.first} - {player.last == 2025 ? "Present" : player.last}
          </div>
        </div>
      </div>

      {!undoUsed && (
        <UndoButton clickHandler={() => undoGuess(player, row, col)} />
      )}
    </li>
  );
}

export function MiniGrid({ col, row, correct }) {
  const cells = [];

  for (let r = 1; r <= 3; r++) {
    for (let c = 1; c <= 3; c++) {
      const color =
        r != row || c != col
          ? "bg-gray-200 group-[.user-row]:bg-orange-50"
          : correct
          ? "bg-green-600"
          : "bg-red-600";

      cells.push(
        <div
          key={`${col}-${row}-${r}-${c}`}
          className={`aspect-square rounded-sm ${color}`}
        ></div>
      );
    }
  }

  return <div className="grid shrink grid-cols-3 gap-px">{cells}</div>;
}

export default PickHistoryDialog;
