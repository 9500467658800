export function LogoKeeperWhite(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 285 51"
      {...props}
    >
      <path
        fill="#000"
        d="m26.345 2.648-.365-.08-.366.08L4.141 7.022l-1.421.28v14.206c0 4.132 1.218 8.105 3.653 11.796 1.867 2.85 4.425 5.538 7.632 7.985 4.303 3.29 8.605 5.257 10.23 5.94l1.014.44.69.281.69-.28 1.015-.402c1.664-.642 6.008-2.528 10.351-5.778 3.207-2.407 5.765-5.096 7.632-7.985 2.395-3.732 3.613-7.784 3.613-12.038V7.303l-1.421-.281-21.474-4.334z"
      />
      <path
        fill="#fff"
        d="M48.387 4.534 26.913.201 26.02 0l-.893.2L3.613 4.535 0 5.256v16.372c0 4.654 1.38 9.108 4.1 13.241 2.03 3.09 4.83 6.02 8.281 8.668 4.546 3.49 9.093 5.577 10.838 6.3l1.015.44L25.94 51l1.705-.682 1.015-.401c1.786-.683 6.414-2.689 11-6.14 3.451-2.608 6.252-5.537 8.282-8.667 2.679-4.173 4.018-8.707 4.018-13.482V5.256l-3.612-.722zm.893 4.213v12.76c0 4.254-1.218 8.307-3.613 12.038-1.867 2.89-4.424 5.578-7.631 7.985-4.344 3.25-8.687 5.096-10.351 5.778l-1.015.402-.69.28-.69-.28-1.015-.442c-1.665-.682-5.927-2.688-10.23-5.938-3.207-2.448-5.764-5.136-7.631-7.985C3.978 29.653 2.76 25.68 2.76 21.548V7.303l1.421-.281 21.433-4.374.366-.08.365.08 21.474 4.334 1.42.28v1.445z"
      />
      <path
        fill="#ED1C24"
        d="M4.506 8.747v12.76c0 3.772 1.136 7.424 3.37 10.835 1.745 2.688 4.18 5.216 7.225 7.543 4.14 3.17 8.28 5.056 9.864 5.738l1.015.442 1.014-.402c1.584-.642 5.765-2.407 9.946-5.537 3.044-2.287 5.48-4.815 7.226-7.544 2.232-3.45 3.328-7.182 3.328-11.115V8.707L26.02 4.374z"
      />
      <path
        fill="#981C24"
        d="M7.185 10.914v10.553c0 3.25.974 6.42 2.923 9.39 1.583 2.447 3.816 4.735 6.616 6.901 3.938 3.01 7.916 4.816 9.256 5.377V7.102z"
      />
      <path
        fill="#fff"
        d="M22.164 26.403v5.457H15.83V15.81h6.333v5.457h2.76l5.968-5.457h8.321l-8.403 8.025 8.403 8.025h-8.321l-5.968-5.457zM86.8 25.45l17.1-13.2H90.4l-12.1 9h-4.5l2.4-9H65.9L59 38.65h10.3l2.4-9h4.5l7.4 9h13.5zM129.5 38.75l1.7-6.8h-20.6l.8-3.4h20.7l1.6-6.1H113l.9-3.4h20.6l1.8-6.8h-31l-6.8 26.5zM171 19.05l1.7-6.8h-30.9l-6.9 26.5h31l1.7-6.8H147l.9-3.4h20.6l1.6-6.1h-20.7l.9-3.4zM211.1 22.25c1.5-5.5-1.9-10.1-7.4-10h-25.5l-6.8 26.4h10.3l1.7-6.5h15.2c5.4 0 11.2-4.5 12.6-10zm-25.8 2.3 1.2-4.5h12.6c.6 0 1.1.2 1.4.7.3.4.4 1 .3 1.6-.2.6-.5 1.1-1.1 1.6-.5.4-1.1.6-1.7.6h-12.7M241.8 31.95h-20.7l.9-3.4h20.7l1.5-6.1h-20.6l.9-3.4h20.6l1.8-6.8h-31l-6.8 26.5H240zM251.3 12.25l-6.8 26.4h10.3l1.7-6.5h9.2l3.3 6.5h11l-3.8-7.4c1.9-.8 3.7-2 5.1-3.6q2.25-2.4 3-5.4c1.5-5.5-1.9-10.1-7.4-10h-25.6m22.6 10c-.2.6-.5 1.1-1.1 1.6q-.75.6-1.8.6h-12.6l1.2-4.5h12.6c1.3 0 2 1 1.7 2.3"
      />
    </svg>
  );
}
